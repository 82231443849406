import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';

import configColor from 'configs/configColor'
import { breakpointsMedias } from 'configs/breakpoints'
import Button from 'components/core/Button';

interface IMenu {
    onClose: () => void
    onShowComming: () => void
}

type LinkMenu = {
    text: string,
    link: string,
    element?: boolean,
    exlink?: boolean,
}


export const MENU_LIST = [
    {
        text: "home",
        link: "/",
    },
    {
        text: "service",
        link: "/#service",
        element: true
    },
    {
        text: "about",
        link: "/about",
    },
    {
        text: "contact",
        link: "/#contact",
        element: true
    },
] as LinkMenu[];

const Menu = ({ onClose, onShowComming }: IMenu) => {
    const { pathname } = useLocation();
    const { t } = useTranslation();

    return (
        <Wrap >
            <div className="menu-img"></div>
            <div className={`menu-list`}>
                {
                    MENU_LIST.map((item, index) => {
                        return !item.element ? <Link
                            to={item.link}
                            key={index}
                            className={`menu-item ${pathname.split("/")[1] === item.text || (item.text === "home" && pathname.split("/")[1] === "") ? "menu-item-active" : ""}`}
                            onClick={() => {
                                onClose()
                            }}
                            target={item.exlink ? "_blank" : ""}
                            rel="noreferrer"
                        >
                            <span className={`size-2-400 color-${pathname.split("/")[1] === item.text || (item.text === "home" && pathname.split("/")[1] === "") ? "gradient" : "white"}`}>{t(item.text)}</span>
                        </Link>
                            : <a
                                key={index}
                                href={item.link}
                                className={`menu-item ${pathname.split("/")[1] === item.text ? "menu-item-active" : ""}`}
                            >
                                <span className={`size-2-400 color-white`}>{t(item.text)}</span>
                            </a>
                    }
                    )
                }
                <a href='/#service' className="bt-learn" >
                    <Button text='learnMore' className='text-uppercase' sizeText={1} />
                </a>
            </div>

        </Wrap >
    )
}

export default Menu

const Wrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #1A1A1A;
    position: relative;
    
    ${breakpointsMedias.min481} {
        max-width: 303px;
    }
    padding: 57px 20px;
    .menu-img {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
   
    .menu-list {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0;
        z-index: 0;
        height: fit-content;
        .menu-item {
            /* margin-bottom: 16px; */
            cursor: pointer;
            display: flex;
            align-items: center;
            width: fit-content;
            margin-bottom: 0px;
            margin-top: 16px;
            > span {
                display: flex;
                align-items: center;
            }
            position: relative;
            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                width: 0;
                height: 1px;
                background: ${configColor.gradient};
                transition: 0.3s;
                left: 50%;
                transform: translateX(-50%);
            }
            &:hover{
                &::after {
                    width: 70%;
                }
            }
        }
        .bt-learn {
            margin-top: 20px;
            width: 150px;
        }
        .menu-item-active {

        }
    }
    ${breakpointsMedias.min1200} {
        flex-direction: row;
        background-color: transparent;
        max-width: unset;
        background: none;
        padding: 0;
        .menu-img {
            display: none;
        }
        .menu-list {
            border: none;
            flex-direction: row;
            align-items: center;
            padding: 0;
            height: 55px;
            .menu-item {
                margin: 0 10px;
                padding: 0 10px;
                &:first-child {
                    margin-left: auto;
                }
            }
            .bt-learn {
                margin-left: 33px;
                height: 55px;
                width: 200px;
                margin-top: 0;
            }
        }
    }
    ${breakpointsMedias.min1400} {
        .menu-list {
            .menu-item {
                margin: 0 20px;
            }
        }
    }
`

interface ISocicalItem {
    active: string
}

const SocicalItem = styled.div`
    background-color: #FFFFFF;
    -webkit-mask: url(${({ active }: ISocicalItem) => active}) no-repeat center;
    mask: url(${({ active }: ISocicalItem) => active}) no-repeat center;
    mask-size: contain;
    /* transition: 0.6s ease-in-out; */
    &:hover {
        background: ${configColor.colorPrimary};
    }
`