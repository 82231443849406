import styled from 'styled-components'
import { breakpointsMedias } from 'configs/breakpoints'
import Footer from 'components/Footer/Footer'

const Term = () => {

    return (<Wrap>
        <div className="container">
            <div className='term-wrap'>
                <span className="size-4 color-gradient term-title">Terms of Service - Quantum Optermuik</span>
                <span className="size-0-400 color-gray term-time">Last Updated: [9:23 UTC +2 / Central European Summer Time (CEST), July 2022]</span>
                <span className="size-1 color-white">Welcome to Quantum Optermuik ("we", "our", "us"). This Privacy Policy outlines how we collect, process, and protect your personal data. By using our services, you agree to the practices described below.</span>
                <span className="size-1-700 color-white term-heading">1. Services Overview</span>
                <span className="size-1 color-white">Quantum Optermuik offers a range of financial services including but not limited to Market Making, Treasury Build and Management, Personalized Customer Needs, Analysis and Advice, Liquidity Providing, Risk Management, and Legal Connect and Advice. Our services are designed to empower you in navigating financial markets effectively and efficiently.</span>
                <span className="size-1-700 color-white term-heading">2. Service Engagement</span>
                <span className="size-1 color-white">Engaging with our services implies that you understand and acknowledge the nature of financial markets and associated risks. It is your responsibility to make informed decisions based on the insights, recommendations, and strategies provided by Quantum Optermuik.</span>
                <span className="size-1-700 color-white term-heading">3. Personalized Services</span>
                <span className="size-1 color-white">Our Personalized Customer Needs service is tailored to your specific requirements. We collaborate closely with you to understand your unique goals and challenges, providing customized strategies to meet your financial objectives.</span>
                <span className="size-1-700 color-white term-heading">4. Data Usage</span>
                <span className="size-1 color-white">While utilizing our services, you may provide personal, financial, and confidential information. Quantum Optermuik respects your privacy and handles your data in accordance with our Privacy Policy. By engaging with our services, you grant us the necessary permissions to process and use your data for service-related purposes.</span>
                <span className="size-1-700 color-white term-heading">5. Financial Risks</span>
                <span className="size-1 color-white">Financial markets are inherently volatile and subject to risk. While our services are designed to provide insights and strategies, Quantum Optermuik does not guarantee specific outcomes or returns. You acknowledge that all financial decisions are ultimately your responsibility.</span>
                <span className="size-1-700 color-white term-heading">6. Legal Advisory:</span>
                <span className="size-1 color-white">Our Legal Connect and Advice service provides guidance on legal matters related to financial markets. However, it is important to note that our legal experts offer general advice and information and should not be considered as a substitute for legal counsel tailored to your specific situation.</span>
                <span className="size-1-700 color-white term-heading">7. Disclaimer of Liability</span>
                <span className="size-1 color-white">Quantum Optermuik is not liable for any losses, damages, or consequences arising from your use of our services or decisions made based on our insights or recommendations. We recommend seeking professional advice and conducting thorough due diligence before making any financial decisions.</span>
                <span className="size-1-700 color-white term-heading">8. Modifications</span>
                <span className="size-1 color-white">We reserve the right to modify, suspend, or discontinue any aspect of our services at any time. We may update these Terms of Service periodically, and it is your responsibility to review the terms regularly.</span>
                <span className="size-1-700 color-white term-heading">9. Contact Information</span>
                <span className="size-1 color-white">If you have any questions or concerns regarding our services or these Terms of Service, please contact us at <a href='/#contact'>Contact Information.</a> By using our services, you acknowledge that you have read, understood, and agreed to abide by these Terms of Service.</span>
                {/*  */}
            </div>
        </div>
        <Footer />
    </Wrap>)
}

export default Term

const Wrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    min-height: 100vh;
    background: #141414;
    padding-top: 103px;
    position: relative;
    &::before {
        position: absolute;
        content: "";
        background: #7BDEB8;
        filter: blur(192.5px);
        height: 30%;
        width: 20vw;
        right: 0%;
        top: 20%;
        transform: translate(30%, -80%);
        z-index: 0;
    }
    .container {
        display: flex;
        .term-wrap {
            width: 100%;
            max-width: 967px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding-bottom: 151px;
            > span {
                text-transform: capitalize;
            }
            .term-title {
            }
            .term-time {
                font-style: italic;
                color: #A6A6A6;
            }
            .term-heading {
                margin-top: 20px;
            }
            ul {
                display: flex;
                flex-direction: column;
                gap: 10px;
                li {
                    transform: translateX(30px);
                    padding-right: 30px;
                }
            }
            a {
                display: inline-block;
                color: #7BDEB8;
                text-decoration: underline;
            }
        }
    }
    ${breakpointsMedias.max1199} {
        margin-top: 0;
    }
    ${breakpointsMedias.max767} {
       
    }
`

