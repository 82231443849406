import { useWidthScreen } from 'helpers/useScreen'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import Logo from './Logo'
import Menu from './Menu'
import MenuButton from './MenuButton'
import { breakpointsMedias } from 'configs/breakpoints'
import ModalComingSoon from 'components/core/ModalComingSoon'

const Header = () => {
    const [show, setShow] = useState<boolean>(false);
    const { width } = useWidthScreen();
    const [showCommingSoon, setShowCommingSoon] = useState<boolean>(false)

    useEffect(() => {
        window.onscroll = function () {
            // We add pageYOffset for compatibility with IE.
            if (window.scrollY >= 10 || window.pageYOffset >= 10) {
                document.getElementById("header")?.classList.add("has-color");
            } else {
                document.getElementById("header")?.classList.remove("has-color");
            }
        };
    }, [])

    const onShowComming = () => {
        setShowCommingSoon(true)
    }

    return (
        <Wrap className='' id="header">
            <div className="wrap-header">
                <div className="logo">
                    <Logo type={1} />
                </div>
                <div className="menu ">
                    {width >= 1200 ? <Menu onClose={() => { }} onShowComming={onShowComming} />
                        : <MenuButton onClick={() => { setShow(!show) }} show={show} />}
                </div>
            </div>
            {show && <div className='bg-shadow'></div>}
            <div className={`menu-mobile ${show && "menu-mobile-active"}`} onClick={() => { setShow(false) }}>
                <Menu onClose={() => { setShow(false) }} onShowComming={onShowComming} />
            </div>
            {showCommingSoon && <ModalComingSoon onClose={() => { setShowCommingSoon(false) }} />}
        </Wrap>
    )
}

export default Header

const Wrap = styled.div`
    display: flex;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
    &.has-color {
        background: #1A1A1A;
    }
    .wrap-header {
        display: flex;
        align-items: center;
        padding: 0 16px;
        ${breakpointsMedias.min768} {
            padding: 0 40px;
        }
        width: 100%;
        z-index: 1;
        height: 44px;
        .menu {

        }
        .logo {
            width: 76px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    .menu-mobile {
        position: fixed;
        width: 100%;
        height: 100%;
        transition: 0.3s;
        top: 0;
        left: -100%;
        z-index: 0;
        /* background-color: #000000d0; */
    }
    .menu-mobile-active {
        left: 0;
        display: flex;
        /* justify-content: flex-end; */
    }
    .bg-shadow {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        z-index: 0;
        background-color: #000000d0;
    }
    ${breakpointsMedias.min1200} {
        justify-content: center;
        position: relative;
        background: #131313;
        &.has-color {
            background: unset;
        }
        .wrap-header {
            max-width: 1920px;
            padding: 0 108px;
            height: 112px;
           
            .menu {
                flex: 1;
            } 
            .logo {
                width: 146px;
                position: unset;
                transform: unset;
            }
        }
        .menu-mobile {
            display: none;
        }
        .bg-shadow {
            display: none;
        }
    }
    ${breakpointsMedias.min1400} {
        .wrap-header {
            /* max-width: 1920px;
            padding: 0 120px;
            height: 176px; */
            .menu {
                flex: 1;
            } 
            .logo {
                width: 146px;
            }
        }
        .menu-mobile {
            display: none;
        }
        .bg-shadow {
            display: none;
        }
    }
`