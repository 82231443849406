import LoadingScreen from 'components/core/LoadingScreen'
import Privacy from 'pages/privacy/Privacy'
import Service from 'pages/service/Service'
import Term from 'pages/term/Term'
import { lazy, Suspense } from 'react'
import { useRoutes } from 'react-router-dom'
const Homepage = lazy(() => import("../pages/home/Homepage"))
const Routers = () => {
    const routes = useRoutes([
        {
            path: '/',
            element:
                <Suspense fallback={<LoadingScreen />}>
                    <Homepage />
                </Suspense>
        },
        {
            path: '/about',
            element:
                <Suspense fallback={<LoadingScreen />}>
                    <Service />
                </Suspense>
        },
        {
            path: '/term',
            element:
                <Suspense fallback={<LoadingScreen />}>
                    <Term />
                </Suspense>
        },
        {
            path: '/privacy',
            element:
                <Suspense fallback={<LoadingScreen />}>
                    <Privacy />
                </Suspense>
        },
    ])
    return routes
}

export default Routers