import styled from 'styled-components'
import { breakpointsMedias } from 'configs/breakpoints'
import Footer from 'components/Footer/Footer'

const Privacy = () => {

    return (<Wrap>
        <div className="container">
            <div className='term-wrap'>
                <span className="size-4 color-gradient term-title">Privacy Policy of Quantum Optermuik</span>
                <span className="size-0-400 color-gray term-time">Last Updated: [9:23 UTC +2 / Central European Summer Time (CEST), July 2022]</span>
                <span className="size-1 color-white">Welcome to Quantum Optermuik ("we", "our", "us"). This Privacy Policy outlines how we collect, process, and protect your personal data. By using our services, you agree to the practices described below.</span>
                <span className="size-1-700 color-white term-heading">1. General Provisions</span>
                <span className="size-1 color-white">This Privacy Policy applies to all visitors, users, and clients of Quantum Optermuik. We are committed to safeguarding your privacy and ensuring that your personal data is handled responsibly and in compliance with applicable data protection laws.</span>
                <span className="size-1-700 color-white term-heading">2. Collection of Personal Data</span>
                <span className="size-1 color-white">We may collect various types of personal data when you interact with Quantum Optermuik, including but not limited to: name, contact information, financial information, and other data necessary for our services.</span>
                <span className="size-1-700 color-white term-heading">3. Principles of Personal Data Processing</span>
                <span className="size-1 color-white">We process your personal data based on the following principles:</span>
                <ul>
                    <li className="size-1 color-white">Legality: We collect and process your data in accordance with applicable laws and regulations.</li>
                    <li className="size-1 color-white">Transparency: We inform you about the purposes and methods of data processing.</li>
                    <li className="size-1 color-white">Purpose Limitation: We only collect and use your data for specific, legitimate purposes.</li>
                    <li className="size-1 color-white">Data Minimization: We only collect data that is relevant, adequate, and necessary for our services.</li>
                    <li className="size-1 color-white">Accuracy: We strive to ensure the accuracy and correctness of your personal data.</li>
                    <li className="size-1 color-white">Data Security: We implement technical and organizational measures to protect your data.</li>
                    <li className="size-1 color-white">Retention: We retain your data for as long as necessary to fulfill the purposes for which it was collected.</li>
                </ul>
                <span className="size-1-700 color-white term-heading">4. Disclosing of Personal Data</span>
                <span className="size-1 color-white">We may disclose your personal data to third parties only in specific circumstances, such as:</span>
                <ul>
                    <li className="size-1 color-white">To provide our services and fulfill contractual obligations.</li>
                    <li className="size-1 color-white">With your explicit consent.</li>
                    <li className="size-1 color-white">To comply with legal obligations or respond to lawful requests from authorities.</li>
                </ul>
                <span className="size-1-700 color-white term-heading">5. List of Actions Performed by the Operator with the Received Personal Data</span>
                <span className="size-1 color-white">We may perform various actions with your personal data, including but not limited to:</span>
                <ul>
                    <li className="size-1 color-white">Collecting, recording, organizing, storing, and structuring data.</li>
                    <li className="size-1 color-white">Processing, altering, retrieving, using, and transmitting data.</li>
                    <li className="size-1 color-white">Restricting or deleting data to fulfill legal obligations.</li>
                </ul>
                <span className="size-1-700 color-white term-heading">6. Final Provisions</span>
                <span className="size-1 color-white">This Privacy Policy may be updated periodically to reflect changes in our practices or legal requirements. We encourage you to review this Policy regularly. By continuing to use our services after any changes are made, you agree to the revised Privacy Policy.</span>
                <span className="size-1 color-white">If you have any questions or concerns regarding our services or these Terms of Service, please contact us at <a href='/#contact' >Contact Information.</a></span>
                <span className="size-1 color-white">Your privacy is important to us, and we are dedicated to protecting it in accordance with the highest standards of data protection.</span>
                {/*  */}
            </div>
        </div>
        <Footer />
    </Wrap>)
}

export default Privacy

const Wrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    min-height: 100vh;
    background: #141414;
    padding-top: 103px;
    position: relative;
    &::before {
        position: absolute;
        content: "";
        background: #7BDEB8;
        filter: blur(192.5px);
        height: 30%;
        width: 20vw;
        right: 0%;
        top: 20%;
        transform: translate(30%, -80%);
        z-index: 0;
    }
    .container {
        display: flex;
        .term-wrap {
            width: 100%;
            max-width: 967px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding-bottom: 151px;
            > span {
                text-transform: capitalize;
            }
            .term-title {
            }
            .term-time {
                font-style: italic;
                color: #A6A6A6;
            }
            .term-heading {
                margin-top: 20px;
            }
            ul {
                display: flex;
                flex-direction: column;
                gap: 10px;
                li {
                    transform: translateX(30px);
                    padding-right: 30px;
                }
            }
            a {
                display: inline-block;
                color: #7BDEB8;
                text-decoration: underline;
            }
        }
    }
    ${breakpointsMedias.max1199} {
        margin-top: 0;
    }
    ${breakpointsMedias.max767} {
       
    }
`

