import imgSpi1 from "assets/images/img-spi-1.png"
import imgSpi2 from "assets/images/img-spi-2.png"
import imgSpi3 from "assets/images/img-spi-3.png"
import imgSpi4 from "assets/images/img-spi-4.png"
import imgSpi5 from "assets/images/img-spi-5.png"

export const dataService = [
    {
        title: "Who We Are",
        text: "At Quantum Optermuik, we are more than just a market-making organization. We are a team of passionate professionals driven by a common goal – to empower you to achieve unparalleled success in your financial endeavors. With a strong foundation forged over years of experience, we have evolved into a formidable force in the industry, serving as a bridge between your aspirations and reality.",
        img: imgSpi1,
        imgLeft: true
    },
    {
        title: "Our Journey",
        text: "With over 4 years of steadfast commitment in the stock market and 3 years of honing our craft in the crypto market, we have garnered insights that only hands-on experience can provide. This journey has led us to refine our strategies, develop unique approaches, and build lasting relationships with our partners.",
        img: imgSpi2,
        imgLeft: false
    },
    {
        title: "Our Achievements",
        text: "As a testament to our dedication and expertise, we have proudly engaged with over 250 satisfied customers. Our ability to establish connections and foster partnerships has enabled us to maintain relationships that transcend transactions. Furthermore, our extensive network includes connections with more than 20 cryptocurrency exchanges (CEX), amplifying our reach and effectiveness.",
        img: imgSpi3,
        imgLeft: true
    },
    {
        title: "A Proven Track Record",
        text: "Our track record speaks volumes. Over the last 2 years, we have managed and overseen the movement of funds totaling more than $500 million. This not only underscores our proficiency but also highlights the immense trust that our clients place in us.",
        img: imgSpi4,
        imgLeft: false
    },
    {
        title: "Our Commitment to You",
        text: "At Quantum Optermuik, we are driven by a singular mission – your success. We work tirelessly to ensure that our expertise translates into tangible outcomes for your investments. Whether you're navigating the complexities of stock markets or venturing into the thrilling world of cryptocurrencies, we stand by your side, guiding you through every twist and turn.",
        img: imgSpi5,
        imgLeft: true
    }
]