import styled from 'styled-components'
import { breakpointsMedias } from 'configs/breakpoints'
import icon1 from 'assets/images/icon-choose-1.png'
import icon2 from 'assets/images/icon-choose-2.png'
import icon3 from 'assets/images/icon-choose-3.png'
import icon4 from 'assets/images/icon-choose-4.png'
import icon5 from 'assets/images/icon-choose-5.png'

const initData = [
    {
        title: "Unmatched Experience",
        text: "Our combined 7 years of professional experience in stock and crypto markets position us as industry leaders",
        icon: icon1,
    },
    {
        title: "Strategic Partnerships",
        text: "With a vast network of connections and CEX affiliations, we offer you unparalleled access to opportunities",
        icon: icon2,
    },
    {
        title: "Proven Results",
        text: "Our proven track record of managing significant funds underscores our ability to deliver results",
        icon: icon3,
    },
    {
        title: "Customer-Centric Approach",
        text: "With more than 250 engaged customers, our commitment to your success is unwavering",
        icon: icon4,
    },
    {
        title: "End-to-End Solutions",
        text: "From market-making to risk management, we provide comprehensive solutions tailored to your needs",
        icon: icon5,
    }
] as ItemData[]

type ItemData = {
    title: string,
    text: string,
    icon: string,
}

const Choose = () => {

    return (<Wrap>
        <div className="container">
            <span className="size-4 color-white text-center">Why Choose Us</span>
            <div className="hw-content">
                {initData.map((item, index) => <div key={index} className='hwc-item appear' >
                    <span className="size-0-600 color-gradient">{item.title}</span>
                    <span className="size-0-400 color-white">{item.text}</span>
                    <div className='hwci-img'>
                        <img src={item.icon} alt="" />
                    </div>
                </div>)}
            </div>
            <span className="size-2-400 color-gray-2 text-center">Join hands with Quantum Optermuik and experience a partnership that transcends boundaries, transforms strategies, and propels you towards success. Your journey to financial excellence begins here.Contact us today to embark on a new era of financial achievement.</span>
        </div>
    </Wrap>)
}

export default Choose

const Wrap = styled.div`
    width: 100%;
    display: flex;
    padding: 50px 0 169px 0;
    justify-content: center;
    .container {
        flex-direction: column;
        align-items: center;
        gap: 32px;
        .hw-content {
            display: flex;
            align-items: center;
            width: 100%;
            gap: 24px;
            .hwc-item {
                flex: 1;
                border-radius: 16px;
                background: #202020;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 24px 16px;
                align-self: stretch;
                transition: 0.5s ease-in-out;
                cursor: pointer;
                > span {
                    text-align: center;
                }
                > span:first-child {
                    width: 100%;
                    border-bottom: 1px solid #3E3E3E;
                    padding-bottom: 12px;
                    margin-bottom: 12px;
                }
                > span:nth-child(2) {
                    margin-bottom: 24px;
                }
                .hwci-img {
                    display: flex;
                    height: fit-content;
                    margin-top: auto;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    > img {
                        width: 43%;
                        height: auto;
                    }   
                }
                &:hover {
                    transform: translateY(-20px);
                }
            }
        }
        > span:last-child {
            line-height: normal;
            text-align: center;
            width: 90%;
            color: #A0A0A0;
        }
    }
    ${breakpointsMedias.max1199} {
        padding: 50px 0 86px 0;
        .container {
            .hw-content {
                gap: 16px;
                .hwc-item {
                    border-radius: 8px;
                    padding: 16px;
                }
            }
        }
    }
    ${breakpointsMedias.max767} {
        .container {
            .hw-content {
                flex-wrap: wrap;
                .hwc-item {
                    flex: unset;
                    width: calc(50% - 8px);
                    &:hover {
                        transform: unset;
                    }
                }
            }
        }
    }
`

