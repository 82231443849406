import styled from 'styled-components'
import { breakpointsMedias } from 'configs/breakpoints'
import { useWidthScreen } from 'helpers/useScreen'

interface IServiceItem {
    img: string,
    className?: string,
    title: string,
    text: string,
    imgLeft?: boolean
}

const ServiceItem = ({ img, className, title, text, imgLeft = false }: IServiceItem) => {
    const { width } = useWidthScreen()

    return (<Wrap className={className}>
        <div className={`container spi-wrap ${imgLeft && "spi-wrap-2"}`}>
            <div className={`spi-left ${width >= 768 ? `appear-${imgLeft ? "right" : "left"}` : "appear"}`}>
                <span className="size-3 color-white">{title}</span>
                <span className="size-0-400 color-white">{text}</span>
            </div>
            <div className={`spi-right ${width >= 768 ? `appear-${imgLeft ? "left" : "right"}` : "appear"}`}>
                <img src={img} alt="" />
            </div>
        </div>
    </Wrap>)
}

export default ServiceItem

const Wrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    .spi-wrap {
        display: flex;
        align-items: center;
        gap: 24px;
        .spi-left {
            flex: 1;
            display: flex;
            flex-direction: column;
            > span:first-child {
                text-transform: capitalize;
                margin-bottom: 10px;
            }
            > span:last-child {
                text-transform: capitalize;
            }
        }
        .spi-right {
            flex: 1;
            display: flex;
            height: fit-content;
            justify-content: center;
            > img {
                width: 100%;
                height: auto;
            }
        }
    }
    .spi-wrap-2 {
        flex-direction: row-reverse;
    }
    ${breakpointsMedias.max1199} {

    }
    ${breakpointsMedias.max767} {
        margin-bottom: 100px;
        .spi-wrap,
        .spi-wrap-2 {
            flex-direction: column-reverse;
            align-items: center;
            justify-content: center;
            gap: 36px;
            .spi-left {
                align-items: center;
                max-width: 400px;
                > span{
                    text-align: center;
                }
            }
            .spi-right {
                max-width: 400px;
                margin-bottom: 6px;
               
            }
        }
    }
`

