import styled from 'styled-components'
import { breakpointsMedias } from 'configs/breakpoints'
import Footer from 'components/Footer/Footer'
import { useEffect } from 'react'
import { dataService } from './dataService'
import ServiceItem from './ServiceItem'
import Choose from './Choose'
import configColor from 'configs/configColor'

const Service = () => {

    useEffect(() => {
        const appear = document.querySelectorAll('.appear') as any;
        const appear2 = document.querySelectorAll('.appear-right') as any;
        const appear3 = document.querySelectorAll('.appear-left') as any;
        const cb = function (entries: any) {
            entries.forEach((entry: any) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('inview');
                }
            });
        }
        const io = new IntersectionObserver(cb);
        for (let item of appear) {
            io.observe(item);
        }
        for (let item of appear2) {
            io.observe(item);
        }
        for (let item of appear3) {
            io.observe(item);
        }
    }, [])

    return (<Wrap>
        <div className="container sp">
            <span className="size-4 color-white sp-text-1">About Quantum Optermuik</span>
            <span className="size-0-400 color-white sp-text-2">Quantum Optermuik stands as a beacon of innovation and expertise in the ever-evolving landscape of financial markets. We are more than just a market-making organization; we are architects of success, sculpting opportunities that drive growth and prosperity.</span>
        </div>
        {dataService.map((item, index) => <ServiceItem
            key={index}
            title={item.title}
            text={item.text}
            img={item.img}
            imgLeft={item.imgLeft}
            className={`spi-${index + 1}`}
        />)}
        <Choose />
        <Footer />
    </Wrap>)
}

export default Service

const Wrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    min-height: 100vh;
    background: #141414;
    padding-top: 108px;
    position: relative;
    > * {
        z-index: 1;
    }
    &::before {
        position: absolute;
        content: "";
        background: #7BDEB8;
        filter: blur(192.5px);
        height: 30%;
        width: 10vw;
        right: 0%;
        top: 10%;
        transform: translate(30%, -80%);
        z-index: 0;
    }
    &::after {
        position: absolute;
        content: "";
        background: #7bdeb878;
        filter: blur(192.5px);
        height: 30%;
        width: 20vw;
        left: 0%;
        top: 70%;
        transform: translate(-60%, -80%);
        z-index: 0;
    }
    .appear {
        transition: all 0.8s;
        opacity: 0;
        transform: translateY(40px);
    }
    .appear-right {
        transition: all 0.8s;
        opacity: 0;
        transform: translateX(40px);
    }
    .appear-left {
        transition: all 0.8s;
        opacity: 0;
        transform: translateX(-40px);
    }
    .appear.inview,
    .appear-right.inview,
    .appear-left.inview {
        opacity: 1;
        transform: none;
        transition-delay: 0.3s;
    }
    > .sp {
        display: flex;
        flex-direction: column;
        .sp-text-1 {
            margin-bottom: 17px;
        }
        .sp-text-2 {
            margin-bottom: 80px;
            max-width: 90%;
            text-transform: capitalize;
        }
    }
    .spi-1 {
        .spi-right {
            > img {
                width: 70%;
            }
        }
    }
    .spi-2 {
        .spi-right {
            > img {
                width: 90%;
            }
        }
    }
    .spi-3 {
        .spi-right {
            > img {
                width: 70%;
            }
        }
    }
    .spi-4 {
        .spi-right {
            > img {
                width: 70%;
            }
        }
    }
    .spi-5 {
        margin-top: -50px;
        .spi-right {
            > img {
                width: 73%;
            }
        }
    }
    ${breakpointsMedias.max1199} {
        margin-top: 0;
        /* background: #1E1E1E; */
    }
    ${breakpointsMedias.max767} {
        > .sp {
            align-items: center;
            .sp-text-1 {
                text-align: center;
            }
            .sp-text-2 {
                max-width: 400px;
                text-align: center;
                position: relative;
                padding-bottom: 24px;
                margin-bottom: 48px;
                &::after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 1px;
                    background: ${configColor.gradient};
                    transition: 0.3s;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }
`

