import styled from 'styled-components'
import { breakpointsMedias } from '../../configs/breakpoints'
import { useState } from 'react'
import Logo from 'components/header/Logo'
import { MENU_LIST } from 'components/header/Menu'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Button from 'components/core/Button'

export const linkFooter = [{
    text: "term",
    link: "/term",
},
{
    text: "privacy",
    link: "/privacy",
},]

const Footer = () => {
    const { t } = useTranslation();
    const [value, setValue] = useState<string>("")

    return (
        <Wrap className=''>
            <div className="container f-top">
                <div className="footer-left">
                    <div className="fl-logo">
                        <Logo type={1} />
                    </div>
                    <div className="fl-list">
                        {MENU_LIST.map((item, index) => {
                            return <Link to={item.link || ""} key={index} className='fl-item'>
                                <span className="size-0-400 color-white">{t(item.text)}</span>
                            </Link>
                        })}
                    </div>
                </div>
                <div className="footer-right">
                    <span className="size-0-400 color-white">Get the freshest news from us</span>
                    <div className="fr-submit">
                        <div className="frs-input">
                            <input
                                className='size-0-400'
                                type="text"
                                placeholder='Your email address…'
                                value={value}
                                onChange={(e: any) => { setValue(e.target.value) }}
                            />
                        </div>
                        <Button text='Submit' sizeText={0} sizeBt='small' />
                    </div>
                </div>
            </div>
            <div className="container f-bot">
                <div className="f-bot-wrap">
                    <div className="ft-list">
                        {linkFooter.map((item, index) => {
                            return <Link to={item.link || ""} key={index} className='ft-item'>
                                <span className="size-ss color-gray-2">{t(item.text)}</span>
                            </Link>
                        })}
                    </div>
                    <span className="copyright text-center size-ss color-gray-2">All rights reserved Quantum Optermuik 2016</span>
                </div>
            </div>

        </Wrap>
    )
}

export default Footer

const Wrap = styled.div`
    padding: 78px 0 76px 0;
    display: flex;
    justify-content: center;
    z-index: 0;
    margin-top: auto;
    flex-direction: column;
    align-items: center;
    background: #1D1D1D;
    width: 100%;
    .f-top {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding-bottom: 80px;
        .footer-left {
            width: 30%;
            display: flex;
            flex-direction: column;
            min-width: 355px;
            .fl-logo {
                width: 70%;
                max-width: 255px;
                margin-bottom: 37px;
            }
            .fl-list {
                display: flex;
                align-items: center;
                gap: 54px;
            }
        }
        .footer-right {
            display: flex;
            width: 30%;
            flex-direction: column;
            min-width: 355px;
            > span {
                margin-bottom: 18px;
            }
            .fr-submit {
                display: flex;
                align-items: center;
                .frs-input {
                    border-radius: 8px;
                    background: #FFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 16px;
                    flex: 1;
                    height: 40px;
                    > input {
                        width: 100%;
                        color: black;
                        &::placeholder {
                            color: #92989F;
                        }
                    }
                }
                > button {
                    width: 120px;
                    margin-left: 8px;
                }
            }
        }
    }
    .f-bot {
        display: flex;
        .f-bot-wrap {
            width: 100%;
            border-top: 1px solid #3E3E3E;
            padding-top: 28px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .ft-list {
                display: flex;
                align-items: center;
                .ft-item {
                    padding: 0 21px;
                    border-right: 1px solid #92989F;
                    &:last-child {
                        border-right: none;
                    }
                    &:first-child {
                        padding-left: 0;
                    }
                }
            }
        }
    }
    ${breakpointsMedias.max1199} {
        padding: 38px 0 36px 0;
        .f-top {
            padding-bottom: 30px;
            .footer-left {
                .fl-logo {
                    margin-bottom: 24px;
                }
                .fl-list {
                    gap: 32px;
                }
            }
            .footer-right {
                min-width: 300px;
                > span {
                    margin-bottom: 12px;
                }
                .fr-submit {
                    .frs-input {
                        padding: 0 12px;
                        height: 32px;
                    }
                    > button {
                        width: 100px;
                    }
                }
            }
        }
        .f-bot {
            .f-bot-wrap {
                padding-top: 16px;
                .ft-list {
                    .ft-item {
                        padding: 0 16px;
                    }
                }
            }
        }
    }
    ${breakpointsMedias.max767} {
        .f-top {
            padding-bottom: 24px;
            flex-direction: column;
            align-items: flex-start;
            .footer-left {
                min-width: unset;
                width: 100%;
                padding-bottom: 12px;
                border-bottom: 1px solid #3E3E3E;
                margin-bottom: 12px;
                .fl-logo {
                    margin-bottom: 24px;
                    max-width: 180px;
                }
                .fl-list {
                    gap: 0;
                    justify-content: space-between;
                    max-width: 375px;
                }
            }
            .footer-right {
                width: 100%;
                min-width: unset;
                max-width: 375px;
            }
        }
        .f-bot {
            .f-bot-wrap {
                flex-direction: column;
                align-items: flex-start;
                .ft-list {
                    margin-bottom: 8px;
                }
            }
        }
    }
`
