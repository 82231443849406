import styled from 'styled-components'
import { useEffect } from 'react'
import iconClose from 'assets/images/icon-close.png';
import { breakpointsMedias } from 'configs/breakpoints';

interface IModalWrap {
    onClose: () => void,
    children: React.ReactElement;
    showCloseBt?: boolean,
    clickOutsideToClose?: boolean,
    maxZ?: boolean,
    onTop?: boolean
}

const ModalWrap = ({ onClose, children, showCloseBt = true, clickOutsideToClose = true, maxZ, onTop }: IModalWrap) => {
    useEffect(() => {
        document.documentElement.style.overflow = "hidden";
        return () => {
            document.documentElement.style.overflow = "auto"
        }
    }, []);

    return (
        <Wrap onClick={(e) => { e.stopPropagation(); clickOutsideToClose && onClose() }} className={`${maxZ ? "max-z" : ""}`}>
            <div className={`modal-wrap ${onTop ? "modal-wrap-2" : ""}`} onClick={(e: any) => { e.stopPropagation() }}>
                <div className='modal-wrap-content bg-gray'>
                    {showCloseBt && <div className="modal-close" onClick={onClose}>
                       <img src={iconClose} alt="" />
                    </div>}
                    <div className="modal-container">
                        {children}
                    </div>
                </div>
            </div>
        </Wrap>
    )
}

export default ModalWrap

const Wrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    justify-content: flex-start;
    align-items: center;
    background-color: #000000e5;
    z-index: 666;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 50px 0;
    -webkit-transform: translate3d(0,0,1px);
    transform: translate3d(0,0,1px);
    &.max-z {
        z-index: 667;
    }
    .modal-wrap {
        margin: auto;
        padding: 2px;
        background: #333333;
        border-radius: 10px;
        .modal-wrap-content {
            background: #1A1A1A;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            position: relative;
            overflow: hidden;
            .modal-close {
                position: absolute;
                top: 12px;
                right: 12px;
                cursor: pointer;
                width: 20px;
                height: 20px;
                > img {
                    width: 100%;
                    height: auto;
                }
            }
            .modal-container {
            }   
        }   
    }
    span {
        white-space: normal;
    }
    .modal-wrap-2 {
        margin: 0 auto 10% auto;
    }
    ${breakpointsMedias.min1200} {
        .modal-wrap {
            .modal-wrap-content {
                .modal-close {
                    width: 30px;
                    height: 30px;
                }
            }   
        }
    }
`