import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from './LoadingSpinner';
import configColor from 'configs/configColor';
import { breakpointsMedias } from 'configs/breakpoints';
import iconArr from 'assets/images/icon-arrow-left.png'

interface IB extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    text: string,
    isLoading?: boolean,
    className?: string,
    disabled?: boolean,
    sizeText?: 0 | 1 | 2,
    sizeBt?: "small" | "normal",
}

const Button = ({ text, isLoading = false, sizeText = 2, sizeBt = "normal", className, disabled = false, ...props }: IB) => {
    const { t } = useTranslation();
    return (
        <Wrap className={`${isLoading ? "bt-loading" : ""} bt-${sizeBt} ${className}`} disabled={disabled || isLoading} {...props}>
            <span className={`size-${sizeText === 2 ? "2-600" : 1 ? "0-600" : "0-400"} color-black`}>{!!isLoading ? <LoadingSpinner />
                : <>
                    {t(text)}
                </>}</span>
        </Wrap>
    )
}
export default Button

const Wrap = styled.button`
    width: 100%;
    height: 46px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50px;
    transition: 0.3s;
    background: ${configColor.gradient};
    &:active,
    &:focus {
        background: ${configColor.gradient};
    }
    padding: 0 6px;
    > span {
        display: flex;
        align-items: center;
    }
    &:hover {
        background: ${configColor.gradient2};
    }
    &.bt-small {
        height: 40px;
    }
    &:disabled{
        opacity: 0.4;
        cursor: not-allowed;
    }
    &.bt-loading {
        opacity: 0.4;
        cursor: not-allowed;
    }
   
    ${breakpointsMedias.max1199} {
        height: 36px;
        &.bt-small {
            height: 32px;
        }
    }
`